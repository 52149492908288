<script>
import DetailFormGroup from '@/components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import FormCascaderFilter from '@/components/FormView/FormCascaderFilter'
import { postAction, getAction, putAction } from '@/command/netTool'
import { handleDeep3, handleDeep4 } from '@/command/dataProcess'
import { identityIDCard } from '@/utils/util'

export default {
  name: 'basicInformationDetail',
  data() {
    return {
      productArr: [], //产品类别列表
      levelArr: [], //五级联动
      detail: {
        regionCode: [],
        dw: '',
        productList: [],
      },
      equipmentNeeds: [],
      insureNeeds: [],
    }
  },
  computed: {
    categoryArr() {
      return this.$store.state.categoryArr
    },
    scjyArr() {
      return this.$store.state.scjyArr
    },
    sctypeArr() {
      return this.$store.state.sctypeArr
    },
  },
  mounted() {
    let arr = ['equment_needs', 'insure_needs']
    Promise.all(arr.map((e) => getAction(`/api/admin/dict/code/${e}`))).then((res) => {
      this.equipmentNeeds = res[0].map((e) => {
        return {
          ...e,
          value: e.id,
        }
      })
      this.insureNeeds = res[1].map((e) => {
        return {
          ...e,
          value: e.id,
        }
      })
    })
    getAction('/api/dataoperation/region/freeTree?level=4').then((res) => {
      this.levelArr = res && res.length > 0 && handleDeep3(res)
      this.$forceUpdate()
    })
    getAction('/api/dataoperation/companyCategory/productCategoryTree').then((res) => {
      this.productArr = res && res.length > 0 && handleDeep4(res)
      this.$forceUpdate()
    })

    const { id } = this.$route.query
    id &&
      getAction(`/api/dataoperation/collectionMainBody/detail/${id}`).then((res) => {
        let arCode = [res.provinceCode, res.cityCode, res.areaCode, res.streetCode]
        let dw = `${res.lng}，${res.lat}`

        let productArr = res.productList.map((e) => {
          return {
            ...e,
            prodctCategoryIdGather: e.prodctCategoryIdGather ? e.prodctCategoryIdGather.split(',') : [],
          }
        })

        let interviewMov = ((res.interviewMov && res.interviewMov.map((e) => e.url)) || []).join(',')

        this.detail = {
          ...res,
          regionCode: arCode,
          dw: dw,
          productList: productArr,
          interviewMov,
        }
      })
    this.$forceUpdate()
  },
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '主体名称',
            type: 'input',
            key: 'mainName',
            cols: 8,
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '统一社会信用代码',
            type: 'input',
            cols: 8,
            key: 'creditCode',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '法定代表人',
            type: 'input',
            cols: 8,
            key: 'personName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '法人身份证',
            type: 'input',
            cols: 8,
            key: 'idCard',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '法人联系电话',
            type: 'input',
            cols: 8,
            key: 'linkPhone',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '主体类型',
            type: 'treeSelect',
            cols: 8,
            key: 'mainBodyType',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            isCheckable: false,
            typeData: this.categoryArr,
          },
          {
            name: '产业类型',
            type: 'selectMode',
            cols: 8,
            key: 'industryType',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            typeData: this.sctypeArr,
            props: {
              mode: 'multiple',
            },
          },
          {
            name: '生产经营者类型',
            type: 'select',
            cols: 8,
            key: 'producerType',
            typeData: this.scjyArr,
          },
        ],
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: '营业地址',
        data: [
          {
            name: '经纬度',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true,
              },
            ],
            gutter: 5,
            children: [
              {
                type: 'input',
                cols: 21,
                key: 'dw',
                label: '经纬度',
                placeholder: '请输入经纬度以中文逗号隔开',
                disabled: true,
              },
              {
                type: 'button',
                label: '定位',
                cols: 3,
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap,
                  },
                },
              },
            ],
          },
          {
            name: '地址',
            type: 'row',
            cols: 12,
            colsType: {
              lg: 24,
              md: 24,
              sm: 24,
              xs: 24,
              xxl: 12,
              xl: 18,
            },
            gutter: 5,
            rules: [
              {
                required: true,
              },
            ],
            children: [
              {
                type: 'cascader',
                cols: 10,
                key: 'regionCode',
                typeData: this.levelArr,
                label: '地址',
                rules: [
                  {
                    required: true,
                    type: 'array',
                    message: '请选择地址',
                  },
                ],
              },
              {
                type: 'input',
                cols: 14,
                key: 'address',
                label: '地址',
                rules: [
                  {
                    required: true,
                    type: 'string',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    setValue(valKey, val) {
      this.detail[valKey] = val
    },
    getSearch(value = '') {
      getAction(`/api/dataoperation/common/getLocation?location=${value}`).then((e) => {
        const { address, provinceCode, provinceName, cityCode, cityName, areaCode, areaName, streetCode, streetName } =
          e
        this.setValue('address', address)
        this.setValue('areaCode', areaCode)
        this.setValue('areaName', areaName)
        this.setValue('provinceName', provinceName)
        this.setValue('provinceCode', provinceCode)
        this.setValue('cityName', cityName)
        this.setValue('cityCode', cityCode)
        this.setValue('streetCode', streetCode || '')
        this.setValue('streetName', streetName || '')
        this.setValue('regionCode', [provinceCode, cityCode, areaCode, streetCode])
        this.$forceUpdate()
      })
    },
    onOpenMap() {
      apiTool.openTheMap({
        title: '打开地图',
        onSuccess: (data) => {
          this.detail.lat = data.lat
          this.detail.lng = data.lng
          this.detail.dw = `${data.lng}，${data.lat}`
          this.getSearch(`${data.lng},${data.lat}`)
          this.$forceUpdate()
        },
      })
    },
    getForm3() {
      return {
        type: 'tabs',
        key: 'selectType',
        title: '证件照',
        children: [
          {
            title: '营业执照',
            data: [
              {
                type: 'upload',
                key: 'tradingCertificate',
                maxLength: 1,
                cols: 2,
                descTitle: '营业执照',
                styles: {
                  width: '102px',
                },
              },
            ],
          },
          {
            title: '法人身份证照',
            data: [
              {
                type: 'upload',
                key: 'cardBefore',
                maxLength: 1,
                cols: 2,
                descTitle: '身份证正面照',
                styles: {
                  width: '102px',
                },
              },
              {
                type: 'upload',
                key: 'cardAfter',
                maxLength: 1,
                cols: 2,
                descTitle: '身份证反面照',
                styles: {
                  width: '102px',
                },
              },
            ],
          },
          {
            title: '开户许可证',
            data: [
              {
                type: 'upload',
                key: 'openAccountLicence',
                maxLength: 1,
                cols: 2,
                descTitle: '开户许可证',
                styles: {
                  width: '102px',
                },
              },
            ],
          },
          {
            title: '走访图片',
            data: [
              {
                type: 'upload',
                key: 'visitImage',
                maxLength: 100,
                cols: 24,
                styles: {
                  width: '102px',
                },
              },
            ],
          },
          {
            title: '走访视频',
            data: [
              {
                type: 'uploadVideo',
                key: 'interviewMov',
                maxLength: 100,
                cols: 24,
                styles: {
                  width: '102px',
                },
              },
            ],
          },
        ],
      }
    },
    getForm4() {
      return {
        type: 'cardForm',
        title: '土地信息',
        data: [
          {
            name: '土地总面积（亩）',
            type: 'input',
            key: 'areaLand',
            cols: 12,
          },
          {
            name: '其中自营土地面积（亩）',
            type: 'input',
            key: 'myselfLand',
            cols: 12,
          },
          {
            name: '其中流转土地面积（亩）',
            type: 'input',
            key: 'circulationLand',
            cols: 12,
          },
          {
            name: '流转土地租金（元/亩）',
            type: 'input',
            key: 'circulationLandMoney',
            cols: 12,
          },
        ],
      }
    },
    getForm5() {
      let that = this
      return {
        type: 'table',
        width: '95%',
        showFootButton: true,
        showRowSelect: false,
        showPagination: false,
        // table新增按钮回调
        onAddData: () => {
          return {
            prodctName: null,
            saleMoney: null,
            prodctCategoryIdGather: [],
            prodctCategoryId: null,
            prodctCategoryDesc: null,
            listingMonth: null,
          }
        },
        columns: [
          {
            dataIndex: 'prodctCategoryIdGather',
            align: 'left',
            title: <div style={{ marginLeft: '20px' }}>产品类别</div>,
            width: 100,
            render: ({ text, records, index }) => {
              const onInput = (data, labelText) => {
                let dataCopy = JSON.parse(JSON.stringify(data))
                records.prodctCategoryIdGather = data
                records.prodctCategoryId = dataCopy.pop()
                records.prodctCategoryDesc = labelText
              }
              return records.isEdit ? (
                <FormCascaderFilter value={text} typeData={that.productArr} onChange={onInput} />
              ) : (
                <div style={{ marginLeft: '20px' }}>{records.prodctCategoryDesc}</div>
              )
            },
          },
          {
            dataIndex: 'prodctName',
            align: 'left',
            title: '产品名称',
            width: 100,
            render: ({ text, records, index }) => {
              const onInput = (data) => {
                records.prodctName = data.target.value
              }
              return records.isEdit ? (
                <a-input placeholder="产品名称" onChange={onInput} value={text} />
              ) : (
                <div>{text}</div>
              )
            },
          },
          {
            dataIndex: 'saleMoney',
            align: 'left',
            title: '销售均价·元/斤',
            width: 50,
            render: ({ text, records }) => {
              const onInput = (data) => {
                records.saleMoney = data.target.value
              }
              return records.isEdit ? (
                <a-input addon-before="￥" placeholder="价格" onChange={onInput} value={text} />
              ) : (
                <div>{text}</div>
              )
            },
          },
          {
            dataIndex: 'listingMonth',
            align: 'left',
            title: '上市月份·月',
            width: 50,
            render: ({ text, records }) => {
              const onInput = (data) => {
                records.listingMonth = data.target.value
              }
              return records.isEdit ? (
                <a-input placeholder="请输入上市月份" onChange={onInput} value={text} />
              ) : (
                <div>{text}</div>
              )
            },
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: '10%',
            title: '操作',
            tableType: 'editDelete',
            onSave: (text, records, index) => {
              records.isEdit = false
            },
          },
        ],
        dataSource: this.detail.productList,
      }
    },
    getForm6() {
      return {
        type: 'cardForm',
        title: '物联网需求',
        data: [
          {
            type: 'checkBoxButton',
            key: 'equmentNeeds',
            cols: 24,
            typeData: [{ type: 'all', value: '0', name: '全选' }, ...this.equipmentNeeds],
          },
          {
            label: '物联网需求备注说明',
            type: 'textArea',
            key: 'equmentNeedsRemark',
            placeholder: '物联网需求备注说明',
            cols: 24,
          },
        ],
      }
    },
    getForm7() {
      return {
        type: 'cardForm',
        title: '保险需求',
        data: [
          {
            type: 'checkBoxButton',
            key: 'insureNeeds',
            cols: 24,
            typeData: [{ type: 'all', value: '0', name: '全选' }, ...this.insureNeeds],
          },
          {
            label: '保险需求备注说明',
            type: 'textArea',
            key: 'insureNeedsRemark',
            placeholder: '保险需求备注说明',
            cols: 24,
          },
        ],
      }
    },
    getSubmitData(data) {
      const codeText = ['province', 'city', 'area', 'street']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = data[i]
      })
      return obj
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          sumbit: true,
          onClick: (data) => {
            if (!identityIDCard(data.idCard).isPass) {
              this.$message.warning('请填写真实有效的身份证号码!')
              return false
            }
            let testPhone =
              /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/
            if (!testPhone.test(data.linkPhone)) {
              this.$message.warning('请填写正确的联系电话!')
              return false
            }
            let codeObj = this.getSubmitData(data.regionCode)
            let { productList } = data
            let productArr = productList.map((e) => {
              return {
                ...e,
                prodctCategoryIdGather: String(e.prodctCategoryIdGather),
              }
            })

            let interviewMov = []
            if (data.interviewMov) {
              interviewMov = data.interviewMov.split(',').map((e) => {
                return {
                  url: e,
                }
              })
            }

            let params = {
              ...data,
              ...codeObj,
              productList: productArr,
              equmentNeeds: data.equmentNeeds ? data.equmentNeeds.toString() : '',
              insureNeeds: data.insureNeeds ? data.insureNeeds.toString() : '',
              interviewMov,
            }

            this.$delete(params, 'regionCode')
            this.$delete(params, 'selectType')

            if (data.id) {
              putAction('/api/dataoperation/collectionMainBody', {
                ...params,
              }).then((res) => {
                this.$router.push('/basicInformation')
              })
            } else {
              postAction('/api/dataoperation/collectionMainBody', {
                ...params,
              }).then((res) => {
                this.$router.push('/basicInformation')
              })
            }
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/basicInformation'),
        },
      ]
      return {
        left,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[
          this.getForm1(),
          this.getForm2(),
          this.getForm3(),
          this.getForm4(),
          this.getForm5(),
          this.getForm6(),
          this.getForm7(),
        ]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
